<section *ngIf="product">
    <div class="card">
        <span class="preorder"> {{ preOrderText }} </span>
        <div class="imagery" aria-hidden="true">
            <xm-media-image [imageOptions]="breakpointImages" center="auto" size="auto"></xm-media-image>
        </div>
        <div class="detail">
            <div class="brand">{{ product.brand }}</div>
            <div class="name">{{ product.deviceName }}</div>
            <div>
                <span class="price" *ngIf="!product.byod">{{ product.recurringChargePrefix }} {{ priceAfterPromotion
                    }}</span><span class="price strike-through" *ngIf="!product.byod && product.priceAfterPromotion">{{ priceBeforePromotion
                    }}</span>
                <span class="price" *ngIf="product.byod">{{ product.priceBeforePromotion }}</span>
                <div class="terms">{{ product.contractTerm }}</div>
                <div class="cta">
                    <button class="xm-btn-primary-light" (click)="shop()" [ngClass]="{ 'disabled': disableCta }" [disabled]="disableCta">{{ product.ctaText }}</button>
                </div>
                <div class="retail-price" *ngIf="!product.byod && product.onetimePrice"> {{ product.oneTimePricePrefix }}
                    {{ oneTimePrice }}
                </div>
            </div>
            <div class="promos" *ngIf="promoLabel">
                <div class="promo">
                    <xm-media-image class="promo-icon" [imageOptions]="promoIcon" size="width"
                        center="vert"></xm-media-image>
                    <span class="promo-text">{{ promoLabel }}</span>
                </div>
            </div>
        </div>
    </div>
</section>