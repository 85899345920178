<section #parentRef class="{{ alignment }} {{pageData.sectionContainer}}" [ngStyle]="{ backgroundColor: pageData.backgroundColor }">
    <div class="wrapper {{pageData.class}}" [ngClass]="{ 'border-style': pageData.showBorder }">
        <ng-container *ngIf="hasFieldOrEditable(rendering.fields.title)">
            <div class="title text-spacing" *scRichText="rendering.fields.title"
                [ngStyle]="{ color: pageData.titleColor }"></div>
        </ng-container>
        <ng-container *ngIf="hasFieldOrEditable(rendering.fields.header)">
            <div class="header text-spacing" *scRichText="rendering.fields.header"
                [ngStyle]="{ color: pageData.headerColor }"></div>
        </ng-container>
        <div class="text-wrapper">
            <xm-cms-text *ngIf="hasFieldOrEditable(rendering.fields.smallBody)" class="small-body text-spacing"
                [field]="rendering.fields.smallBody" [ngStyle]="{ color: pageData.smallBodyColor }"></xm-cms-text>
        </div>
        <div class="actions" *ngIf="hasActions">
            <a rel="noopener" target="_blank" class="link" [href]="rendering?.fields?.linkDecorationOne?.value?.url">{{
                rendering?.fields?.linkDecorationOne?.value?.text }}
                <xm-media-image *ngIf="breakpointImages" class="link-icon" aria-hidden="true" [imageOptions]="breakpointImages"></xm-media-image>
            </a>
        </div>
    </div>
</section>