<section #parentRef class="{{pageData.sectionContainer}}" [ngStyle]="{ backgroundColor: pageData.backgroundColor }">
    <div class="banner {{ bannerheight }}" [ngStyle]="backgroundImgStyles">
        <div class="desktop-overlay" *ngIf="pageData.showOverlay" [ngStyle]="{ backgroundColor: pageData.backgroundColor }"></div>
    </div>
    <div class="wrapper" [ngClass]="getClassName()">
        <div *ngIf="pageData.showTextBox" class="text-container {{ textAlignment }} {{ linkClass }}" [ngClass]="{ 'flipped': pageData.flipped }">
            <xm-media-image *ngIf="headerIcon" class="header-image" [imageOptions]="headerIcon"></xm-media-image>
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.smallTitle)">
                <div class="small-title" *scRichText="rendering.fields.smallTitle" [ngStyle]="{ color: pageData.smallTitleColor }"></div>
            </ng-container>
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.header)">
                <div class="header" *scRichText="rendering.fields.header" [ngStyle]="{ color: pageData.headerColor }"></div>
            </ng-container>
            <div class="popover-button-container">
                <xm-cms-text
                    *ngIf="hasFieldOrEditable(rendering.fields.description)"
                    class="description"
                    [ngStyle]="{ color: pageData.descriptionColor }"
                    [field]="rendering.fields.description">
                </xm-cms-text>
                <xm-popover
                    *ngIf="rendering.fields.popover"
                    class="popover-button"
                    [theme]="linkClass"
                    [rendering]="rendering.fields.popover"
                    [parentTemplate]="parentRef"
                    position="right-bottom">
                </xm-popover>
            </div>
           
            <div class="separator" *ngIf="pageData.showSeparator"></div>
            <xm-media-image *ngIf="footerIcon" class="footer-icon" [imageOptions]="footerIcon"></xm-media-image>
            <xm-cms-text *ngIf="hasFieldOrEditable(rendering.fields.footerNote)" class="footer-note" [field]="rendering.fields.footerNote"></xm-cms-text>
            <div class="actions hero-button">
                <xm-generic-link *ngIf="hasFieldOrEditable(rendering.fields.genericLinkOne)" class="cta left" [field]="rendering.fields.genericLinkOne"></xm-generic-link>
                <xm-generic-link *ngIf="hasFieldOrEditable(rendering.fields.genericLinkTwo)" class="cta" [field]="rendering.fields.genericLinkTwo"></xm-generic-link>
                <xm-cms-text *ngIf="hasFieldOrEditable(rendering.fields.buttonOne)" class="cta" [customClass]="'hero-banner-button'" [field]="rendering.fields.buttonOne"></xm-cms-text>
                <xm-cms-text *ngIf="hasFieldOrEditable(rendering.fields.buttonTwo)" class="cta" [customClass]="'hero-banner-button'" [field]="rendering.fields.buttonTwo"></xm-cms-text>
            </div>
            <xm-cms-text *ngIf="hasFieldOrEditable(rendering.fields.disclaimerText)" [theme]="linkClass" class="disclaimer-text" [field]="rendering.fields.disclaimerText"></xm-cms-text>
        </div>
    </div>
</section>
