<section *ngIf="products.length">
    <div class="header-section">
        <ng-container *ngIf="hasFieldOrEditable(rendering.fields.header)">
            <h2 id="shop-module" class="headline" *scRichText="rendering.fields.header"></h2>
        </ng-container>
        <ng-container *ngIf="hasFieldOrEditable(rendering.fields.subtitle)">
            <p class="subtitle" *scRichText="rendering.fields.subtitle"></p>
        </ng-container>
        <div class="cta">
            <xm-generic-link *ngIf="hasFieldOrEditable(rendering.fields.linkOne)" [field]="rendering.fields.linkOne"></xm-generic-link>
            <xm-generic-link *ngIf="hasFieldOrEditable(rendering.fields.linkTwo)" [field]="rendering.fields.linkTwo"></xm-generic-link>
        </div>
    </div>
    <div class="carousel-with-controls">
        <span #prevButton class="swiper-navigation-prev" *ngIf="showArrows">
            <xm-media-image [imageOptions]="leftArrowIcon" size="height"></xm-media-image>
        </span>

        <div class="swiper-container" [swiper]="swiperConfig" [a11yLabel]="pageData.swiperAriaLabel" focusSelector="a">
            <div class="swiper-wrapper">
                <!-- <ng-container *ngIf="hasPlaceholder(rendering, 'byodCard')">
                    <div class="placeholders swiper-slide" sc-placeholder name="byodCard" [rendering]="rendering"></div>
                </ng-container> -->
                <xm-product-card class="swiper-slide" *ngFor="let product of products" [product]="product" [pageData]="pageData.productCard" [tps]="tps" [extendedPromos]="pageData.extendedPromos"></xm-product-card>
            </div>
        </div>

        <span #nextButton class="swiper-navigation-next" *ngIf="showArrows">
            <xm-media-image [imageOptions]="rightArrowIcon" size="height"></xm-media-image>
        </span>
         <!-- Navigation controls -->
        <span class="swiper-pagination" *ngIf="showLentils"></span>
    </div>     
</section>
